.mb-2rem {
    margin-bottom: 1.5rem !important;
}
.mb-1rem {
    margin-bottom: 1rem !important;
}
.mt-2rem {
    margin-top: 1.5rem !important;
}

.fb-main {
    position: relative;
}

.fb-fields {
    max-height: 90vh;
    overflow-y: auto;
    position: sticky;
    top: 0;
    left: 0;
}

.fb-underlined {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

.fb-title {
    font-size: 16px !important;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px !important;
}

.fb-fields, .fb-builder, .fb-properties {
    border-radius: 0;
    padding: 10px 20px;
}

.fb-field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #B1CCE7;
    padding: 5px 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.fbf-icon {
    margin-right: 10px;
}

.fb-builder-section {
    border: 1px dashed #E7EBEF;
    border-radius: 5px;
    padding: 15px;
    padding-top: 30px;
    position: relative;
    background-color: #FAFAFA;
}

.fb-sec-drag-handle {
    background-color: white;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    cursor: grab;
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
}

.fb-section-actions {
    display: flex;
    justify-content: flex-end;
}

.fb-builder-column {
    margin: 5px;
    border: 1px dashed #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    position: relative;
}

.fb-builder-column.highlight {
    border-bottom: 5px solid #008CD1 !important;
}

.fb-remove-column, .fb-add-column, .fb-delete-section {
    position: absolute !important;
    top: -15px !important;
    left: -15px !important;
}

.fb-add-column {
    left: 15px !important;
}

.fb-column-title {
    font-size: 12px;
    color: #ccc;
}

.fb-builder-content.highlight {
    border-bottom: 5px solid #008CD1;
    border-radius: 10px;
}

.fb-builder-content-children {
    border-radius: 5px;
    border: 2px dashed #ddd;
    min-height: 60vh;
    padding: 20px;
}

.fb-builder-content-children .is-empty {
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B1CCE7;
}

.fb-element-divider {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.fb-element-divider .fbe-divider {
    height: 1px;
    width: 100%;
    background-color: #ddd;
}

.fb-element-divider .delete-divider {
    margin-right: 0;
}

.fb-div-drag-handle {
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    cursor: grab;
}

.fb-element-divider.highlight {
    padding: 10px;
    background-color: red;
}

.fb-form-control {
    position: relative;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 4px;
}

.fb-form-control.fb-form-custom-field {
    padding: 4px;
    border: 1px dotted #ddd;
    border-radius: 8px;
}

.fb-button-settings-wrapper {
    display: flex;
}


#form-builder-dialog .fb-form-control {
    min-height: 32px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.fb-default-control {
    width: 100%;
    padding: 15px;
    background: white;
    color: #008CD1;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 80px;
}

.fb-default-control .disabled-note {
    color: rgb(244, 105, 105);
    font-size: 12px;
}

.fb-form-control.highlight {
    padding-top: 10px;
    border-top: 5px solid #363d41 !important;
}

.fb-fc-settings, .fb-fc-drag-handle, .fb-fc-remove {
    top: 10px;
    right: 40px;
    padding: 5px;
    cursor: pointer;
    color: #008CD1;
    background-color: transparent;
    border: none;
}

.fb-fc-drag-handle {
    right: 5px;
    color: black;
    cursor: grab;
}

.fb-fc-remove {
    color: lightcoral;
}

.fb-fc-settings:hover, .fb-fc-drag-handle:hover {
    color: blue;
}

.fb-manage-options {

}

.fb-manage-options .add-form {
    display: flex;
    align-items: flex-start;
}

.fb-manage-options .add-form .add-btn {
    position: relative;
    top: -5px;
}

.fb-builder-section.highlight {
    border-top: 5px solid #363d41;
    border-radius: 10px;
}

.is-preview .fb-builder-section {
    border: none;
    background-color: transparent;
    padding: 0;
}

.is-preview .fb-builder-column {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
}