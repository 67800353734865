mark {
	padding: 0;
	background-color: #ffd279;
}

.ais-ClearRefinements {
	margin: 1em 0;
}

.ais-SearchBox {
	margin-top: 1em;
}

.ais-Stats {
	margin-bottom: 2rem;
	text-align: end;
	color: #5c609b;
	text-decoration: underline;
	font-size: 0.9em;
}

.ais-IndexLabel {
	color: #777;
	background: #eee;
	padding: .5em;
	font-weight: bold;
	margin: 0;
}

.left-panel {
	float: left;
	width: 250px;
	padding: 1.5rem 1rem;
}

.right-panel {
	padding: 1rem;
	margin-left: 250px;
}

.ais-InstantSearch {
	max-width: 960px;
	overflow: hidden;
	margin: 0 auto;
}

.ais-Hits-item {
	margin-bottom: 1em;
	width: calc(50% - 1rem);
}

.ais-Hits-item img {
	margin-right: 1em;
	width: 100%;
	height: 100%;
	margin-bottom: 0.5em;
}

.hit-name {
	margin-bottom: 0.5em;
}

.hit-description {
	color: grey;
	margin-bottom: 0.5em;
}

.hit-info {
	font-size: 90%;
}

.ais-SearchBox-input {
	background-color: #f5f5fa;
	border: 0;
	box-shadow: none;
}

.ais-RefinementList-checkbox {
	box-shadow: none;
}

.ais-RefinementList-item--selected .ais-RefinementList-label .ais-RefinementList-labelText {
	line-height: 1.75rem;
}

.ais-RefinementList-item,
.ais-RefinementList-item--selected {
	padding-left: 0.25rem;
	margin-left: -0.25rem;
}

.ais-RefinementList-item:hover,
.ais-RefinementList-item--selected:hover {
	background-color: #f5f5f5;
}
.ais-RefinementList-item--disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	pointer-events: none;
}

.empty-hits {
	font-size: 1.5em;
	background: #f5f5fa50;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.empty-hits h3 {
	color: #777;
}

.empty-hits h6 {
	color: #888;
}

.ais-Pagination {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ais-HitsPerPage-select {
	height: 2rem;
}

.pagination-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap-reverse;
}

.ais-Panel {
	position: relative;
	width: 100%;
}

.ais-ClearRefinements-link {
	position: absolute;
	top: 0;
	right: 0;
	line-height: 1rem;
	vertical-align: middle;
	text-transform: uppercase;
	font-size: 0.65rem;
	font-weight: 600;
	color: #999;
}

.ais-Panel-header {
	border-bottom: 1px solid #5a5e9a;
}

@media screen and (max-width: 768px) {
	.left-panel {
		width: 100%;
		float: unset;
		position: relative;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: flex-end;
		padding: 0;
	}

	.ais-Panel {
		margin-bottom: 1rem;
	}

	.right-panel {
		margin: 0;
		width: 100%;
		float: unset;
		margin-top: 2rem;
	}

	.ais-RefinementList-list {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
	}

	.ais-RefinementList-list .ais-RefinementList-item,
	.ais-RefinementList-list .ais-RefinementList-item--selected {
		margin-right: 1rem;
		min-width: 150px;
	}

	.ais-Pagination-item--page:not(.ais-Pagination-item--selected) {
		display: none;
	}
}

@media screen and (max-width: 425px) {
	.ais-Pagination {
		margin: auto;
	}

	.ais-HitsPerPage {
		margin: 1rem auto;
	}
}
